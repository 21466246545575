(function (w) {
    const xiongqi = w.XQ_SDK || {};
    xiongqi.loginCallback = null
    xiongqi.loginoutCallback = null
    w.addEventListener('message', function (e) {
        if (!e.data) return;
        switch (e.data.action) {
            case "callback:login":
                xiongqi.loginCallback && xiongqi.loginCallback(e.data)
                break;
            case "callback:logout":
                xiongqi.loginoutCallback && xiongqi.loginoutCallback()
                break;
        }
    })

    // 合并参数
    xiongqi.extend = function (target, options) {
        if (target === undefined || target === null) {
            return options;
        } else {
            if (options) {
                for (const name in options) {
                    target[name] = options[name];
                }
            }
            return target;
        }
    }
    // 发送信息
    xiongqi.sendMessage = function (data) {
        window.top.postMessage(data, '*')
    }
    // 支付
    xiongqi.pay = function (data) {
        const defaults = {
            action: "pay",
            uid: 0,
            extend: '',
            amount: 1,
            game_player_id: '',
            game_player_name: '',
            role_level: '',
            server_id: '',
            server_name: '',
            props_name: '',
        };
        data = xiongqi.extend(defaults, data)
        xiongqi.sendMessage(data)
    }
    xiongqi.setLogoutCallback = function (logoutCallback) {
        xiongqi.loginoutCallback = logoutCallback
    }
    // 登陆
    xiongqi.login = function (callback) {
        xiongqi.loginCallback = callback
        const data = {
            action: 'login',
        }
        xiongqi.sendMessage(data)
    }
    // 退出登陆
    xiongqi.logout = function () {
        const data = {
            action: 'logout'
        }
        xiongqi.sendMessage(data)
    }
    // 上传角色信息
    xiongqi.uploadRole = function (data) {
        const defaults = {
            action: 'uploadRole',
            uid: 0,
            server_id: 0,
            server_name: "",
            role_id: "",
            role_name: "",
            role_level: "",
            combat_number: "",
        }
        data = xiongqi.extend(defaults, data)
        xiongqi.sendMessage(data)
    }
    w.XQ_SDK = xiongqi;
})(window)
